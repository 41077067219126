import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from "gatsby";
import AfspraakBtn from '../components/AfspraakBtn';

// eslint-disable-next-line
export const OnderhoudPageTemplate = class extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
      <section className='container'>
        <h1 className="title mt-4 mb-3"><b>Onderhoud</b></h1>
        <h3 className="title mt-4 mb-3">
          Waarom een onderhoudsbeurt voor uw haard?<br />
          Jaarlijks onderhoud aan uw haard voorkomt storingen en verlengt de levensduur van uw haard.
          Laat daarom elk jaar uw haard onderhouden door onze ervaren servicemonteur van Hans Haardenservice.
        </h3>
        <div className="hh-tarief mt-6">
          <div className="hh-tarief-container">
            <h4>Eenmalig onderhoud gashaard</h4>
            <ul>
              <li>Reinigen van het glas en de binnenkant van de haard</li>
              <li>Reinigen van waakvlam en brander en de beluchting van de brander</li>
              <li>Volledige controle van een veilige werking</li>
              <li>Herschikken van de houtstammen en accessoires</li>
              <li>Visuele controle van het vlambeeld</li>
            </ul>
            <p>Meerprijs grote haard: &euro; 25</p>
            <div className="separator"></div>
            <div className="prijs-totaal mt-3">
              <div className="prijs">&euro; 150</div>
              <div className="prijs-info">(inclusief voorrijkosten)</div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <AfspraakBtn />
        </div>
        
        <div className="hh-tarief-info mt-6">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
            <p>Deze prijzen zijn exclusief btw <br />
Bij meerdere toestellen in huis ontvangt u 10% korting per extra toestel op het onderhoud
&gt;100km vanuit Sint Katelijne Waver is een meerprijs van €25
Kosten van eventueel te vervangen onderdelen , steeds in overleg. <br />
Direct te betalen na voltooing, betaling mogelijk met cash, bancontact of Payconiq</p>
              </div>
            </div>
        </div>
      </section>
    );
  }
}

OnderhoudPageTemplate.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

const OnderhoudPage = ({data}) => {
	const { frontmatter } = data.markdownRemark;
	return (
		<Layout pageTitle='Hans Haardenservice • Onderhoud' pageDescription={frontmatter.description}>
			<OnderhoudPageTemplate />
		</Layout>
	)
};

OnderhoudPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default OnderhoudPage

export const pageQuery = graphql`
  query OnderhoudPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "onderhoud-page" } }) {
      frontmatter {
        title
        description
      }
    }
  }
`;
